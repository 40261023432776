import React, {useEffect, useState, useContext} from 'react';
import { Context } from '../../App';
import './DispoTable.css';
import TableComponent from '../TableComponent/TableComponent';
const axios = require('axios');


// EXAMPLE NEW DISPO
// {
//   "_id": {
//       "$oid": "60f0c57a35632a9b0b9fff8f"
//   },
//   "hoaName": "",
//   "rep": "DSantiago@momentumsolar.com",
//   "MeterOnPrem": "NO",
//   "relationtosigner": "Owner",
//   "state": "CT",
//   "cornerstone": "NO",
//   "utility": "Eversource",
//   "HOA": "NO",
//   "address": "85 arrowhead trail",
//   "hoaPhone": "",
//   "url": "https://proposalpdf.s3.amazonaws.com/CT/OP-392353/Cash/CollisBoyce-07-15-2021-18:07:55pm.pdf",
//   "partner": "",
//   "RoofIssue": "NO",
//   "hoaEmail": "",
//   "oppnum": "OP-392353",
//   "panels": "52",
//   "term": "",
//   "onpremmeter": "",
//   "design": "https://proposalimages2.s3.amazonaws.com/OP-392353/designImage-07-15-2021-06:19:46-pm",
//   "repNotes": "Homeowner will be finishing renovations in the catwalk walkway to the barn section of the roof / property over the next 6 months - once this is done we can finish installing the remaining panels that go here. ",
//   "lastupdated": "2021-07-15 19:32:09",
//   "epc": "49400",
//   "groundMountNotes": "Add Notes Here",
//   "ppw": "2.5",
//   "activityid": "254a8b19-d7e3-eb11-bacb-00224803f689",
//   "financer": "Cash",
//   "MeterComboTwo": "",
//   "mts_demoschedulingid": "254a8b19-d7e3-eb11-bacb-00224803f689",
//   "TreeIssue": "NO",
//   "roofNotes": "Add Notes Here",
//   "MeterCombo": "NO",
//   "OpenCeiling": "NO",
//   "gatecode": "",
//   "systemsize": "19.76",
//   "MeterAccount": "5122 252 7038",
//   "Pets": "NO",
//   "oppid": "OP-392353",
//   "OpenCeilingNotes": "Add Notes Here",
//   "treeNotes": "Add Notes Here",
//   "MeterNumber": "886641252",
//   "hoaRep": "",
//   "nameonbill": "Collis H Boyce",
//   "GroundMount": "NO",
//   "petsNotes": "Add Notes Here",
//   "MeterComboOne": "",
//   "customername": "Collis Boyce",
//   "manager": "HGoncalves@momentumsolar.com",
//   "GatedCommunity": "NO"
// }

export default function DispoTable() {
  const { selectedDispo, setSelectedDispo, latestDispos, setLatestDispos } = useContext(Context);

  const getNewestDispos = () => {
    axios.post('https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/last3kdispo')
      .then(function (response) {
        console.log(response.data)
        setLatestDispos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getNewestDispos();
  }, [])

  const clickDispo = (e) => {
    console.log(e)
  }

  return (
    <main>
      <TableComponent
          headers={[
            {
              title: "OPPORTUNITY NUMBER",
              type: "oppnum",
            },
            {
              title: "CUSTOMER NAME",
              type: "customername",
            },
            {
              title: "ADDRESS",
              type: "address",
            },
            {
              title: "STATE",
              type: "state",
            },
            {
              title: "UTILITY",
              type: "utility",
            },
            {
              title: "SALES REP",
              type: "rep",
            },

            {
              title: "UTILITY BILL",
              type: "utilitybill",
            }
          ]}
          rows={latestDispos}
          clickRow={e => clickDispo(e)}
        />
    </main>
  )
}
