import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Context } from "../../App";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { TableStyle } from "./styles";


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "65vh",
  },
});

const TableComponent = ({ headers, rows, keyValue, ...props }) => {
  const { page, setPage, selectedDispo, setSelectedDispo } = useContext(Context);
  //  const [isFiltered,setIsFiltered]

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [localRows, setLocalRows] = useState([]);

  useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleRowClick = (e) => {
    const dispo = localRows.find(row => row.oppnum === e.currentTarget.id)

    setSelectedDispo(dispo);
  }

  const onFilter = (e) => {
    //we want to handle filtering of rows based on id
    const id = e.currentTarget.id;
    let newRows = localRows.sort((a, b) => {
      let nA = a[id].toLowerCase(),
        nB = b[id].toLowerCase();
      if (nA < nB)
        //sort string ascending
        return -1;
      if (nA > nB) return 1;
      return 0; //default return value (no sorting)
    });
    setLocalRows([...newRows]);
    // alert(e.currentTarget.id);
  };

  const classes = useStyles();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{ width: "90%" }}
    >
      <TableStyle>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((i) => {
                  console.log()
                  return <TableCell className="head" align="center">
                    {i.title}
                  </TableCell>
                })}
                {props.start && (
                  <TableCell className="head" align="center"></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {localRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((v) => {
                  return (
                    <TableRow id={v.oppnum} onClick={(e) => handleRowClick(e)}>
                      {headers.map((keyVal, index) => {
                        return (
                        <TableCell
                          style={{ padding: "5px", textAlign: "center" }}
                          key={index}
                          onClick={() => console.log(index)}
                        >
                          {v[keyVal.type]}
                        </TableCell>
                      )})}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={localRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableStyle>
    </motion.div>
  );
};

export default TableComponent;
