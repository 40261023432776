import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { Context } from "../App";
import LoginButton from "./LoginButton";

const StyledLogin = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: #21283e;
  font-family: ${(props) => props.theme.font};
  .loginCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
    border-radius: 8px;
    background-color: #ffffff;
  }
  h3 {
    text-align: center;
  }
  h6 {
    margin: 15px 40px 55px 40px;
    text-align: center;
  }

  h5 {
    text-align: center;
  }
  .noMatch {
    font-family: ${(props) => props.theme.font};
    margin: 25px 0;
    font-size: 20px;
    text-align: center;
  }

  .form-width {
    width: 12.6;
  }
`;

const Login = ({ login, logout }) => {
  const { setLogout } = useContext(Context);
  //   useEffect(() => {
  //     setLogout(logout);
  //   }, []);
  //we need to do this weird thing because logout is acting like iiffe
  const wrappedLogout = () => {
    return logout;
  };
  const loginClick = (e) => {
    e.preventDefault();
    // history.push("/");
    setLogout(wrappedLogout);
    login();
  };
  return (
    <StyledLogin>
      <div className="loginCard">
        <LoginButton onClick={loginClick} />
      </div>
    </StyledLogin>
  );
};

export default Login;
