import React, { useState, useContext } from "react";
import './App.css';
import PublicRoute from './AuthRouter';

import Header from './Components/Header/Header';
import SelectedDispo from './Components/SelectedDispo/SelectedDispo';
import Search from './Components/Search/Search';
import DispoTable from './Components/DispoTable/DispoTable';

// Set up Context
const Context = React.createContext({});
export { Context };

function App() {
  // State
  const [logout, setLogout] = useState("");
  const [azureAccount, setAzureAccount] = useState("");
  const [page, setPage] = useState(0);
  const [selectedDispo, setSelectedDispo] = useState({});
  const [latestDispos, setLatestDispos] = useState([]);


  return (
    <Context.Provider value={{
      setAzureAccount,
      setLogout,
      page,
      setPage,
      selectedDispo,
      setSelectedDispo,
      latestDispos,
      setLatestDispos
    }}>
      <PublicRoute>
        <div className="App">
          <Header />
          <SelectedDispo />
          <Search />
          <DispoTable />
        </div>
      </PublicRoute>
    </Context.Provider>
  );
}

export default App;
