import React, { useEffect, useState, useContext } from "react";
import { AzureAD } from "react-aad-msal";
// import { Route } from "react-router-dom";
import Login from "./Login/Login";
import { authProvider } from "./Auth/authConfig";
import { Context } from "./App";
const PublicRoute = (props) => {
  const { setAzureAccount, setLogout } = useContext(Context);

  return (
    <AzureAD provider={authProvider}>
      {({ accountInfo }) => {
        setAzureAccount(accountInfo);

        return accountInfo ? (
          <>
            {props.children}
          </>
        ) : (
            <Login
              logout={authProvider.logout}
              login={authProvider.login}
              // history={props.history}
            />
        );
      }}
    </AzureAD>
  );
};

export default PublicRoute;