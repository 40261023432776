import React, {useState, useContext} from 'react';
import './Search.css';
import { Context } from '../../App';
import axios from 'axios';


export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCategory, setSearchCategory] = useState("name");
  const { selectedDispo, setSelectedDispo, latestDispos, setLatestDispos } = useContext(Context);


  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSelectChange = (e) => {
    setSearchCategory(e.target.value);
  }

  const handleSearch = () => {
    let config = {};
    if (searchTerm === "") {
      return axios.post('https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/last3kdispo')
      .then(function (response) {
        setLatestDispos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    if (searchCategory === "name") {
      const data = JSON.stringify({
        "name": searchTerm
      });
      config = {
        method: 'post',
        url: 'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/dispobycustomername',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
    }

    if (searchCategory === "address") {
      const data = JSON.stringify({
        "address": searchTerm
      });
      config = {
        method: 'post',
        url: 'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/dispobyaddress',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
    }

    if (searchCategory === "oppnum") {
      const data = JSON.stringify({
        "oppnum": searchTerm
      });
      config = {
        method: 'post',
        url: 'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/searchbyopdispo',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
    }

    axios(config)
      .then(function (response) {
        setLatestDispos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  return (
    <div className="search-bar">
      <input className="search-input" onChange={handleChange} value={searchTerm} palceholder="Enter a search term" />
      Search By
      <select name="search-category" id="search-category" onChange={handleSelectChange} value={searchCategory}>
        <option value="name">Customer Name</option>
        <option value="address">Customer Address</option>
        <option value="oppnum">Opportunity Number</option>
      </select>
      <button className="search-button" onClick={handleSearch}>Search</button>
    </div>
  )
}
