import React from 'react'
import './Header.css';
import logo from '../../images/mslogo.png';
import Logout from './logout.svg'

export default function Header() {
  const logoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem("msal.idtoken");
    window.location.reload();
  };


  return (
    <header>
      <section className="left">
        <img className="logo" src={logo} alt="logo" />
        Momentum Solar
      </section>
      <h1>Sales Dispo Sheet</h1>
      <p className="log" onClick={logoutClick}>
        <img className="button" height="40px" src={Logout} alt="logout" />
      </p>
    </header>
  )
}
