import React, { useContext, useState } from 'react';
import './SelectedDispo.css';
import { Context } from '../../App';

export default function SelectedDispo() {

  const { selectedDispo, setSelectedDispo } = useContext(Context);

  const oppInfo = ["lastupdated", "customername", "rep", "manager", "state", "oppnum", "design", "url", "utilitybill"];

  const generalInfo = ["GroundMount", "GatedCommunity", "gatecode", "Pets", "OpenCeiling", "RoofIssue", "TreeIssue"];

  const systemInfo = ["epc", "panels", "ppw", "systemsize", "financer", "partner", "term"];

  const meterInfo = ["RoofIssue", "MeterCombo", "MeterComboOne", "MeterComboTwo", "MeterAccount", "MeterOnPrem"];

  const hoaInfo = ["HOA", "hoaName", "hoaEmail", "hoaPhone", "hoaRep"];

  const notes = ["repNotes", "groundMountNotes", "roofNotes", "OpenCeilingNotes", "treeNotes", "petsNotes", ]
  
  const [selectedNote, setSelectedNote] = useState("repNotes")

  const notesSelector = () => {
    return (
      <select value={selectedNote} onChange={e => setSelectedNote(e.target.value)}> 
        {notes.map(key => {
          return <option value={key}>{key}</option>
        })}
      </select>
    )
  }

  const mapElements = arr => {
    if (arr === oppInfo) {
      return arr.map(key => {
        if ((key === "design" || key === "url" || key === "utilitybill") && selectedDispo[key]) {
          return (
            <article className="opp-row" key={key}>
              <a href={selectedDispo[key] || ""} rel="noreferrer" target="_blank">Link to {key}</a>
            </article>
          )
        }
        if (key !== "design" && key !== "url" && key !== "utilitybill") {
          return (
            <article className="opp-row" key={key}>
              <h2>{key}</h2>
              <p>{selectedDispo[key] || "N/A"}</p>
            </article>
          )
        }
        return (
          <article className="opp-row" key={key}>
              <h4>Link to {key}</h4>
              <p>{"N/A"}</p>
            </article>
        )
      })
    } else {
      return arr.map(key => {
        return (
          <article className="project-row" key={key}>
            <h4>{key}</h4>
            <p>{selectedDispo[key] || "N/A"}</p>
          </article>
        )
      })
    }

    
  }

  return (
    <section>
      {selectedDispo.oppnum ?
        <article className="selected-dispo-wrapper">
          <section className="opp-info">
            {
              mapElements(oppInfo)
            }
          </section>
          <section className="notes-container">
            Notes
            {
              notesSelector()
            }
            {
              selectedNote &&
              <article className="note-box">{selectedDispo[selectedNote] || "Select a Note"}</article>
            }
          </section>
          <article className="project-info">
            <section className="project-subsection">
              <p>General Info</p>
              {mapElements(generalInfo)}
            </section>
            <section className="project-subsection">
              <p>System / Prop Info</p>
              {mapElements(systemInfo)}
            </section>
            <section className="project-subsection">
              <p>Meter Info / Issues</p>
              {mapElements(meterInfo)}
            </section>
            <section className="project-subsection">
              <p>HOA Info</p>
              {mapElements(hoaInfo)}
            </section>
          </article>
        </article>
      :
        <article className="selected-dispo-wrapper">
          <section className="no-dispo">Select (click)a Dispo Sheet below to view more detailed information here.</section>
        </article>
      }
    </section>
  )
}
